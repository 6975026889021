import CircledBackButton from "@/components/CircledBackButton";
import IconLoader from "@/components/IconLoader";
import LoadingSpinner from "@/components/LoadingSpinner";
import MemberConsultationCard from "@/components/MemberConsultationCard";
import {
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import {
  GetMemberConsultationsItem,
  Member,
  ProductAdministratorSupportedServiceChannelAvailability,
} from "@/services/core-api-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Divider, ListItem, Stack, Typography } from "@mui/material";
import { useMachine } from "@xstate/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { intercareFlowStateMachine } from "../../machine";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

interface ActiveConsultationsProps {
  consultations: GetMemberConsultationsItem[];
  availabilityData: ProductAdministratorSupportedServiceChannelAvailability[];
  onActiveConsultationSelected: (
    consultation: GetMemberConsultationsItem
  ) => void;
  onStartNewConsultation: () => void;
}

export function ActiveConsultations({
  consultations,
  availabilityData,
  onActiveConsultationSelected,
  onStartNewConsultation,
}: ActiveConsultationsProps) {
  const [stateMachineState] = useMachine(intercareFlowStateMachine);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dependents, setDependents] = useState<Member[]>([]);

  useEffect(() => {
    setDependents(stateMachineState.context.membersList);
  }, [stateMachineState.context]);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Active consultations",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Active consultations",
      pageCategory: "Medical assistance",
    });
  }, []);

  function onBackButtonClick() {
    navigate(-1);
  }

  function onStartNewConsultationButtonClick() {
    onStartNewConsultation();
  }

  function onActiveConsultationClick(consultation: GetMemberConsultationsItem) {
    trackUserInteraction({
      linkText: "MedicalAssistance | Continue consultation",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onActiveConsultationSelected(consultation);
  }

  function getMemberName(memberId: any) {
    return (
      dependents.find((dependent) => dependent.memberDetails.id === memberId)
        ?.memberDetails?.memberFullName || ""
    );
  }

  return (
    <Stack
      color="neutral.dark"
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Stack p={2} spacing={2}>
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>
        <Stack>
          <IconLoader
            icon="ChatWithTimerIcon"
            color="error"
            sx={{
              fontSize: convertPxToRem(56),
              alignSelf: "center",
            }}
          />
          <Stack textAlign="center" spacing={1} py={4}>
            <Typography variant="h1" color="inherit">
              {t("ActiveConsultations.title")}
            </Typography>

            <Typography variant="body1" color="inherit" px={2}>
              {t("ActiveConsultations.subTitle")}
            </Typography>
          </Stack>
          {dependents.length > 0 && consultations.length > 0 && (
            <Stack spacing={4}>
              <Stack
                aria-label="Member consultations list"
                component="ul"
                role="list"
                spacing={1}
              >
                {consultations.map(
                  (consultation: GetMemberConsultationsItem) => (
                    <ListItem
                      aria-label={`${getMemberName(consultation.memberId)} list item`}
                      key={consultation.consultationId}
                      role="listitem"
                      sx={{ padding: "2px 0" }}
                    >
                      <MemberConsultationCard
                        memberName={getMemberName(consultation.memberId)}
                        consultation={consultation}
                        availabilityData={availabilityData}
                        onConsultationClick={onActiveConsultationClick}
                        shouldDisplayStatus={true}
                      />
                    </ListItem>
                  )
                )}
              </Stack>
              <Stack display="flex" position="relative">
                <Divider sx={{ borderWidth: ".5px", bgcolor: "grey.300" }} />
                <Typography
                  variant="body2"
                  fontWeight={600}
                  fontSize={convertPxToRem(10)}
                  sx={{
                    color: theme.palette.neutral[50],
                    top: "50%",
                    left: "50%",
                    textAlign: "center",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    zIndex: 1,
                    p: 0.5,
                    borderRadius: theme.shape.borderRadius,
                    background: () => `${theme.palette.primary[700]}`,
                    width: 35,
                  }}
                >
                  {t("ActiveConsultations.dividerLabel")}
                </Typography>
              </Stack>
            </Stack>
          )}

          {(!consultations || !consultations.length) && (
            <Stack py={1} alignItems="center">
              <LoadingSpinner />
            </Stack>
          )}
        </Stack>

        <Stack direction="row" spacing={2}>
          <ButtonWithAnalytics
            page="MedicalAssistance"
            intent="navigational"
            text={t("ActiveConsultations.startNewSessionButtonLabel")}
            onClick={onStartNewConsultationButtonClick}
            variant="text"
            color="primary"
            endIcon={<IconLoader icon="ArrowInCircleIcon" />}
            style={{ justifyContent: "space-between" }}
            fullWidth
          >
            {t("ActiveConsultations.startNewSessionButtonLabel")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </Stack>
  );
}
