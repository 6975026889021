import CircledBackButton from "@/components/CircledBackButton";
import DefaultAccordion from "@/components/DefaultAccordion";
import IconLoader from "@/components/IconLoader";
import WatermarkIcon from "@/components/WatermarkIcon";
import { trackEvent } from "@/services/analytics-adapter";
import { convertPxToRem } from "@/utils";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface NurseConsultMoreInfoMoreInfoProps {
  onBackButtonClick: () => void;
}

export default function NurseConsultMoreInfo({
  onBackButtonClick,
}: NurseConsultMoreInfoMoreInfoProps) {
  const { t } = useTranslation();

  function trackAccordionExpandEvent(accordionType: string) {
    trackEvent({
      event: `action.nextStepsMoreInfoAccordionExpanded`,
      type: accordionType,
      source: "Medical Assistance",
    });
  }

  function onOnlineNurseConsultAccordionExpand(isExpanded: boolean) {
    if (isExpanded) {
      trackAccordionExpandEvent("OnlineNurseConsult");
    }
  }

  function onOnlineGPConsultAccordionExpand(isExpanded: boolean) {
    if (isExpanded) {
      trackAccordionExpandEvent("OnlineGPConsult");
    }
  }

  function onInPersonGPConsultAccordionExpand(isExpanded: boolean) {
    if (isExpanded) {
      trackAccordionExpandEvent("InPersonGPConsult");
    }
  }

  return (
    <Stack
      justifyContent="flex-end"
      textAlign="center"
      p={2}
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={2} py={4}>
        <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        <IconLoader
          icon="FaceWithInformationIcon"
          color="accent1"
          sx={{
            fontSize: convertPxToRem(90),
            alignSelf: "center",
          }}
        />
        <Typography color="inherit" variant="h2">
          {t("MedicalAssistanceMoreInfo.title")}
        </Typography>
        <Typography color="inherit" variant="h4">
          {t("MedicalAssistanceMoreInfo.subtitle")}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <DefaultAccordion
          key={t(
            "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.title"
          )}
          title={t(
            "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.title"
          )}
          subtitle={t(
            "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.subTitle"
          )}
          titleVariant="h4"
          titleFontWeight={600}
          accordionStyles={{ textAlign: "left" }}
          onExpand={onOnlineNurseConsultAccordionExpand}
          icon={
            <WatermarkIcon
              iconSize="medium"
              color="accent2.light"
              foreground="primary"
              Icon={"CheckmarkIcon"}
            />
          }
        >
          <Stack spacing={2} textAlign="left">
            <Stack
              component="ul"
              role="list"
              aria-label={t(
                "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.title"
              )}
            >
              <ListItem role="listitem" sx={{ padding: 0 }}>
                <Typography variant="body2" fontWeight="bold">
                  {t(
                    "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.whatToExpect"
                  )}
                </Typography>
              </ListItem>
              {(
                t<any, any, string[]>(
                  "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.listItems",
                  {
                    returnObjects: true,
                  }
                ) as []
              ).map((listItemText: string) => (
                <ListItem
                  role="listitem"
                  sx={{ padding: "2px 0" }}
                  key={listItemText}
                >
                  <ListItemIcon sx={{ display: "contents" }}>
                    <IconLoader
                      icon="HandDrawnCircleIcon"
                      sx={{
                        fontSize: convertPxToRem(10),
                        color: "accent2.dark",
                        alignSelf: "center",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ pl: 2 }}>
                    <Typography variant="body2">{listItemText}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </Stack>
          </Stack>
        </DefaultAccordion>
        <DefaultAccordion
          key={t("MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.title")}
          title={t("MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.title")}
          subtitle={t(
            "MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.subTitle"
          )}
          accordionStyles={{ textAlign: "left" }}
          titleVariant="h4"
          titleFontWeight={600}
          onExpand={onOnlineGPConsultAccordionExpand}
          icon={
            <WatermarkIcon
              iconSize="medium"
              color="accent2.light"
              foreground="primary"
              Icon={"CheckmarkIcon"}
            />
          }
        >
          <Stack spacing={2} textAlign="left">
            <Stack
              component="ul"
              role="list"
              aria-label={t(
                "MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.title"
              )}
            >
              <ListItem role="listitem" sx={{ padding: 0 }}>
                <Typography variant="body2" fontWeight="bold">
                  {t(
                    "MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.whatToExpect"
                  )}
                </Typography>
              </ListItem>
              {(
                t<any, any, string[]>(
                  "MedicalAssistanceMoreInfo.OnlineGPConsult.Accordion.listItems",
                  {
                    returnObjects: true,
                  }
                ) as []
              ).map((listItemText: string) => (
                <ListItem
                  role="listitem"
                  sx={{ padding: "2px 0" }}
                  key={listItemText}
                >
                  <ListItemIcon sx={{ display: "contents" }}>
                    <IconLoader
                      icon="HandDrawnCircleIcon"
                      sx={{
                        fontSize: convertPxToRem(10),
                        color: "accent2.dark",
                        alignSelf: "center",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ pl: 2 }}>
                    <Typography variant="body2">{listItemText}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </Stack>
          </Stack>
        </DefaultAccordion>
        <DefaultAccordion
          key={t("MedicalAssistanceMoreInfo.InPersonGPConsult.Accordion.title")}
          title={t(
            "MedicalAssistanceMoreInfo.InPersonGPConsult.Accordion.title"
          )}
          subtitle={t(
            "MedicalAssistanceMoreInfo.InPersonGPConsult.Accordion.subTitle"
          )}
          titleVariant="h4"
          titleFontWeight={600}
          onExpand={onInPersonGPConsultAccordionExpand}
          icon={
            <WatermarkIcon
              iconSize="medium"
              color="accent2.light"
              foreground="primary"
              Icon={"CheckmarkIcon"}
            />
          }
        >
          <Stack spacing={2} textAlign="left">
            <Stack
              component="ul"
              role="list"
              aria-label={t(
                "MedicalAssistanceMoreInfo.InPersonGPConsult.Accordion.title"
              )}
            >
              {(
                t<any, any, string[]>(
                  "MedicalAssistanceMoreInfo.InPersonGPConsult.Accordion.listItems",
                  {
                    returnObjects: true,
                  }
                ) as []
              ).map((listItemText: string) => (
                <ListItem
                  role="listitem"
                  sx={{ padding: "2px 0" }}
                  key={listItemText}
                >
                  <ListItemIcon sx={{ display: "contents" }}>
                    <IconLoader
                      icon="HandDrawnCircleIcon"
                      sx={{
                        fontSize: convertPxToRem(10),
                        color: "accent2.dark",
                        alignSelf: "center",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ pl: 2 }}>
                    <Typography variant="body2">{listItemText}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </Stack>
          </Stack>
        </DefaultAccordion>
      </Stack>
    </Stack>
  );
}
