import FullscreenBackground from "@/components/FullscreenBackground";
import IconLoader from "@/components/IconLoader";
import WatermarkIcon from "@/components/WatermarkIcon";
import {
  trackEvent,
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

interface NurseConsultPromptProps {
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
}

function StepsCounter({ stepCounter }: any) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 30,
        width: 30,
        alignSelf: "center",
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: "accent2.light",
          fontSize: convertPxToRem(30),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          color: "accent2.dark",
          mr: 2,
        }}
      >
        {stepCounter}
      </Typography>
    </Box>
  );
}

export function NurseConsultPrompt({
  onContinueButtonClick,
  onBackButtonClick,
}: NurseConsultPromptProps) {
  const { t } = useTranslation();

  function onContinue() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Medical Assistance",
    });
    trackUserInteraction({
      linkText: "Continue | Medical assistance next steps",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onContinueButtonClick();
  }

  function onBack() {
    trackUserInteraction({
      linkText: "Back | Medical assistance next steps",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onBackButtonClick();
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Medical assistance next steps",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Medical assistance next steps",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          sx={{
            height: "100%",
          }}
        >
          <Stack spacing={6}>
            <Stack
              direction="row"
              alignSelf="center"
              alignItems="center"
              spacing={2.5}
              p={2}
            >
              <WatermarkIcon
                height={36}
                width={36}
                color={"neutral.50"}
                foreground="neutral.50"
                Icon={"FaceIconBackground"}
                iconSize="large"
              />

              <IconLoader
                icon="ArrowNextIcon"
                sx={{
                  fontSize: convertPxToRem(25),
                  alignSelf: "center",
                  color: "neutral.50",
                }}
              />
              <Box
                src={`/assets/MedicalAssistancePartners/Intercare/logo.svg`}
                component="img"
                sx={{
                  zIndex: 2,
                }}
                alt={t("CVPFlow.screens.HealthRecord.animationAlternateText")}
              />
            </Stack>

            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography variant="h2">
                {t("MedicalAssistanceV2.stepsPanel.title")}
              </Typography>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={1} />
                </Stack>
                <Stack>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey="MedicalAssistanceV2.stepsPanel.step1Label"></Trans>
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={2} />
                </Stack>

                <Stack>
                  <Stack>
                    <Typography ml={2} variant="body1">
                      <Trans i18nKey="MedicalAssistanceV2.stepsPanel.step2Label"></Trans>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={3} />
                </Stack>
                <Stack>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey="MedicalAssistanceV2.stepsPanel.step3Label"></Trans>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBack}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button onClick={onContinue} color="info" fullWidth>
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
      </Stack>
    </>
  );
}
