import useGetMemberships from "@/hooks/useGetMemberships";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { Member } from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import { Stack, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircledBackButton from "@/components/CircledBackButton";
import IconLoader from "@/components/IconLoader";
import MedicalAssistanceMembersDropdown from "@/components/MedicalAssistanceMembersDropdown";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

interface MedicalAssistanceDependantSelectionProps {
  onNextButtonClick: (data?: any) => void;
}

export function MedicalAssistanceDependantSelection({
  onNextButtonClick,
}: MedicalAssistanceDependantSelectionProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    firstMembership,
    memberships,
    isMembershipsLoading,
    membershipsFetchError,
  } = useGetMemberships();

  const [selectedMember, setSelectedMember] = useState<Member>();

  const [shouldShowMembersDropdown, setShouldShowMembersDropdown] = useState<
    boolean | null
  >(null);

  function onBackButtonClick() {
    navigate("/home");
  }

  function onHandleNextButtonClick(member: Member) {
    onNextButtonClick(member);
  }

  function handleSelectedMember(selectedMemberMembershipID: any) {
    const getSelectedMember = firstMembership.members.find(
      (member) => member.membershipDetails.id === selectedMemberMembershipID
    );
    setSelectedMember(getSelectedMember as any);
  }

  useEffect(() => {
    if (isMembershipsLoading === false && membershipsFetchError === false) {
      const memberHasDependants = firstMembership.members.length > 1;
      if (!memberHasDependants) {
        onNextButtonClick();
      } else {
        setShouldShowMembersDropdown(memberHasDependants);
      }
    }
  }, [
    memberships,
    shouldShowMembersDropdown,
    isMembershipsLoading,
    membershipsFetchError,
  ]);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Select dependent",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Select dependent",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <>
      <Stack
        color="neutral.dark"
        sx={{
          minHeight: "100%",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Box p={2}>
          <Stack>
            <CircledBackButton
              showLabel={true}
              label={t("common.backButton")}
              onClick={onBackButtonClick}
            />
          </Stack>
          <Stack>
            <IconLoader
              icon="ChatHeartIcon"
              color="error"
              sx={{
                fontSize: convertPxToRem(56),
                alignSelf: "center",
              }}
            />
            <Stack textAlign="center" spacing={2} py={4}>
              <Typography variant="h1" color="inherit">
                {t("MedicalAssistanceDependentSelection.title")}
              </Typography>

              <Typography variant="h3" color="inherit" px={2}>
                {t(`MedicalAssistanceDependentSelection.subTitle`)}
              </Typography>
            </Stack>
            <Typography variant="body2">
              {t("MedicalAssistanceDependentSelection.selectMemberLabel")}
            </Typography>

            {shouldShowMembersDropdown && (
              <MedicalAssistanceMembersDropdown
                setSelectedMembershipId={handleSelectedMember}
                memberships={memberships}
                selectMemberLabel=""
                selectMemberPlaceholder={t(
                  "MedicalAssistanceDependentSelection.selectMemberPlaceholder"
                )}
              />
            )}
          </Stack>
        </Box>

        <ButtonWithAnalytics
          page="Medical assistance dependant selection"
          text={t(`common.nextButton`)}
          intent="navigational"
          color={"primary"}
          variant="contained"
          onClick={() => onHandleNextButtonClick(selectedMember as any)}
          fullWidth
          disabled={!selectedMember}
        >
          {t(`common.nextButton`)}
        </ButtonWithAnalytics>
      </Stack>
    </>
  );
}
